<template>
  <feed v-if="team.feedList" :feed-list-uid="team.feedList" :on="team" allow-alert />
</template>

<script>
  import Feed from '@/modules/feeds/components/Feed'
  import {FeedList} from '@/models'


  export default {
    name: 'TeamFeed',
    components: {Feed},
    props: {
      team: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        feedListUid: null
      }
    },
    computed: {
      feedList() {
        return this.team && this.team.feedList ? FeedList.get(this.team.feedList) : null
      }
    },
    async created() {
      await this.loadFeed()

    },
    methods: {
      async loadFeed() {
        this.loading = true

        try {
          await this.team.fetchFeedList()
        } catch (e) {
          this.$handleApiError(e)
          await this.$router.push({name: 'my-teams'})
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>

